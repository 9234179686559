import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import FormWrapper from "./components/FormWrapper";
import Container from "../Container/Container";
import { P, H3 } from "../GlobalStyles/GlobalStyles";

const NewsletterForm = ({
  form,
  html,
  formId,
  formOnly,
  header,
  subheader,
  bodytext,
  intl,
  useRecaptcha
}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (useRecaptcha) {
      // append recaptcha to head
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://www.google.com/recaptcha/api.js";
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  }, []);

  useEffect(() => {
    // close messages after 10 seconds
    if (error) {
      setTimeout(() => {
        setError("");
      }, 10000);
    }

    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 10000);
    }
  }, [error, success]);

  useEffect(() => {
    /* load and init parsleyjs, load language file */
    import("jquery").then(jquery => {
      window.jQuery = jquery.default;
      window.$ = jquery.default;

      import("parsleyjs").then(parsley => {
        let lang = "";
        const languageRegex = /^\/(en)/;
        const langString = languageRegex.exec(window.location.pathname);

        if (langString) {
          lang = langString[1];
        }

        if (!lang) {
          import("parsleyjs/dist/i18n/de.js");
        } else {
          import(`parsleyjs/dist/i18n/${lang}.js`);
        }

        window.Parsley.addValidator("maxFileSize", {
          validateString: function (_value, maxSize, parsleyInstance) {
            if (!window.FormData) {
              alert("Your Browser does not support file uploads");
              return true;
            }
            var files = parsleyInstance.$element[0].files;
            return files.length != 1 || files[0].size <= maxSize * 1024;
          },
          requirementType: "integer",
          messages: {
            en: "Max %s Kb",
            de: "Maximal %s Kb."
          }
        });

        if (formId) {
          $("#" + formId)
            .parsley()
            .on("field:validated", function () {
              var ok = $(".parsley-error").length === 0;
              $(".bs-callout-info").toggleClass("hidden", !ok);
              $(".bs-callout-warning").toggleClass("hidden", ok);
            });

          $("#" + formId).on("submit", function (e) {
            e.preventDefault();
            var form = $(this);
            var action = form.attr("action");
            var captcha_el = document.querySelector(".g-recaptcha-response");
            var errorContainer = document.querySelector("#captchaError");
            var captcha = captcha_el && captcha_el.value;

            form.parsley().validate();

            if (!captcha && useRecaptcha) {
              if (errorContainer) {
                errorContainer.innerHTML = `<span style='color: #e42131'>${intl.messages["form.default.message.captchaError"]
                  }</span>`;
              }
            }

            if (
              (form.parsley().isValid() && captcha) ||
              (form.parsley().isValid() && !useRecaptcha)
            ) {
              const formData = new FormData($("form")[0]);

              if (!captcha && useRecaptcha) {
                // reset captcha error
                errorContainer.innerHTML = "";
              }

              $.ajax({
                type: "POST",
                cache: false,
                processData: false,
                contentType: false,
                url: "https://typo3.brandung.de" + action,
                data: formData,
                success: function (response) {
                  const res = JSON.parse(response);
                  const message = res && res.message;

                  if (message === "formComplete") {
                    setSuccess(intl.messages["form.default.message.success"]);
                  }

                  if (message === "sandbox applied") {
                    setSuccess(intl.messages["form.default.message.success"]);
                  }

                  if (message === "User applied") {
                    setSuccess(
                      intl.messages["form.default.message.success.job"]
                    );
                  }

                  if (message === "newsletter registration applied") {
                    setSuccess(
                      intl.messages["form.default.message.success.newsletter"]
                    );
                  }

                  // reset form and validation
                  form[0].reset();
                  form.parsley().reset();

                  // add captcha back to form after reset to keep captcha valid
                  if (useRecaptcha) {
                    captcha_el.value = captcha;
                  }
                },
                error: function (error) {
                  if (error.responseText) {
                    const message = JSON.parse(error.responseText).message;
                    const code = JSON.parse(error.responseText).code;
                    if (message === "validationError") {
                      setError(
                        intl.messages["form.default.message.validationError"]
                      );
                    }

                    if (code === "personioError") {
                      setError(
                        intl.messages["form.default.message.personioError"]
                      );
                    }
                  } else {
                    setError(
                      intl.messages["form.default.message.defaultError"]
                    );
                  }
                }
              });
            } else {
            }
          });
        }
      });
    });
  }, []);

  if (formOnly) {
    return (
      <>
        <FormWrapper formOnly>
          <div
            dangerouslySetInnerHTML={{
              __html: form || html
            }}
          />
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
        </FormWrapper>
      </>
    );
  } else {
    return (
      <FormWrapper>
        <Container>
          {header && <H3>{header}</H3>}
          {subheader && (
            <P as="div" dangerouslySetInnerHTML={{ __html: subheader }}></P>
          )}
          {bodytext && (
            <P as="div" dangerouslySetInnerHTML={{ __html: bodytext }}></P>
          )}
          <div
            className="row"
            dangerouslySetInnerHTML={{
              __html: form || html
            }}
          />
          {error && (
            <div
              className="error-message"
              dangerouslySetInnerHTML={{ __html: error }}
            ></div>
          )}
          {success && (
            <div
              dangerouslySetInnerHTML={{ __html: success }}
              className="success-message"
            ></div>
          )}
        </Container>
      </FormWrapper>
    );
  }
};

NewsletterForm.defaultProps = {
  formOnly: false,
  useRecaptcha: false
};

export default injectIntl(NewsletterForm);
